import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class MutasiStockService {
    readMutasiStockQuery(){
        var query = `
            query ( $paging: ServerPaging, $status:String) {
                GetStockMutationV (Paging: $paging, Status: $status) {
                    StockMutationV{
                      mut_id
                      storage_id
                      mut_data
                      mut_reason
                      status
                      created_by
                      created_by_dtl {
                        userid
                        username
                        mypwd
                        myname
                        roleid
                        rname
                        last_login
                        created_at
                      }
                      created_at
                    }
                    Total
                }
            }
        `;
        return query;
    }

    async getMutasiStockQuery(id){
        const variables = {
            id : id
        }
        var query = gql`
            query ($id: Int) {
                GetStockMutationV (MutationId: $id) {
                    StockMutationV {
                        mut_id
                        storage_id
                        mut_data
                        mut_reason
                        status
                        created_by
                        created_by_dtl {
                          userid
                          username
                          mypwd
                          myname
                          roleid
                          rname
                          active_flag
                          last_login
                          contact_id
                          contact_name
                          contact_type
                          current_debit
                          created_at
                          last_update
                        }
                        created_at
                        last_update
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetStockMutationV.StockMutationV[0];
    }

    readItemDataQuery(){
        var query = `
            query ($id: String, $paging: ServerPaging) {
                GetItemStockDetailUnnested(StorageId: $id, Paging: $paging){
                    item_stock {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        uom
                        base_uom
                        conv_amount
                        stock
                    }
                    total
                }
            }
        `;
        return query;
    }

    async readItemStockQuery(variables){
        var query = gql`
            query ( $StorageId: String) {
                GetItemStockGudangV2(StorageId: $StorageId){
                    ItemStockV{
                        storage_id
                        item_id
                        stock
                    }
                }
            }
        `;
        var queryData = await globalfunc.defaultApolloQueryDefault(query, variables);
        var itemStockData = queryData.data.GetItemStockGudangV2.ItemStockV;
        var itemStockArray = [];
        for (let i = 0; i < itemStockData.length; i++) {
            var str = {
                item_id: itemStockData[i].item_id,
                stock: itemStockData[i].stock
            }
            itemStockArray.push(str);
        }
        return itemStockArray;
    }

    async addQuery(variables){
        var query = gql`mutation ( $data: NewStockMutation! ) {
            InsertStockMutation( NewMutation: $data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation ( $id: Int!, $status: String! ) {
            UpdateStockMutation( MutationId: $id, Status: $status){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getStorageId(){
        var query = gql`
            query{
                GetStorage{
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var label = result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'
            var str = {value:result.data.GetStorage[i].storage_id, label:label}
            storageData.push(str);
        }
        return storageData
    }

    gridDataConverter(data){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                item_id: data[i].item_id,
                item_name: data[i].item_name,
                product_code: data[i].product_code,
                stock: data[i].stock,
                qty_change: data[i].qty_change == undefined ? 0 : data[i].qty_change,
                uom: data[i].uom,
                conv_amount: data[i].conv_amount,
            }
            array.push(str);
        }
        return array;
    }

    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if (selected == '') {
                var newData = data;
                return newData;
            }
            else {
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.item_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }
        else {
            if (selected == '') {
                var newData = [];
                return newData;
            }
            else {
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.item_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

    mutationArrayConverter(data){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                item_id: data[i].item_id,
                item_name: data[i].item_name,
                stock: data[i].stock,
                qty_change: data[i].qty_change,
                uom: data[i].uom,
                conv_amount: data[i].conv_amount,
            }
            array.push(str);
        }
        return array;
    }

    itemGridValidation(data){
        var newData = data.split(";");
        
        let array = [];
        for (let i = 0; i < newData.length; i++) {
            var str = newData[i].split("|");
            array.push(str[0]);
        }

        var isDuplicate = array.some(function(item, idx){ 
            return array.indexOf(item) != idx 
        });

        if(isDuplicate){
            return null;
        }else{
            return array.toString();
        }
    }

    selectedItemConverter(data){
        var newData = data.split(";");
        let array = [];
        for (let i = 0; i < newData.length; i++) {
            var splitData = newData[i].split("|");
            var str = {
                item_id: parseInt(splitData[0]),
                product_code: splitData[1],
                item_name: splitData[2],
                stock: parseFloat(splitData[3]),
                qty_change: 0,
                uom: splitData[4],
                conv_amount: parseFloat(splitData[5])
            }
            array.push(str);
        }

        return array;
    }

    itemMutasiStockConverter(data){
        let array = [];
        if(data.length > 0){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    item_id: data[i].item_id, 
                    uom: data[i].uom,
                    conv_amount: parseFloat(data[i].conv_amount),
                    qty: parseFloat(data[i].qty_change),
                    qty_change: parseFloat(data[i].qty_change) * parseFloat(data[i].conv_amount),
                }
                array.push(str)
            }
        }
        return array;
    }
}

export default new MutasiStockService();