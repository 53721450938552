<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Mutasi Stock</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <MutasiStock-grid ref="grid" :key="gridReload" :editClick="editClick"/>
                <MutasiStock-form ref="MutasiStockModal" :addItemClick="addItemClick" :reload="reload"/>
                <item-form ref="itemDataForm" :saveMutasiStockItemGrid="saveMutasiStockItemGrid"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import MutasiStockService from '../MutasiStock/Script/MutasiStockServices.js';
import MutasiStockGrid from '../MutasiStock/Grid/MutasiStockGrid.vue';
import MutasiStockForm from '../MutasiStock/Component/MutasiStockForm.vue';

import ItemDataForm from '../MutasiStock/Component/ItemDataForm.vue';

export default {
    name: 'MutasiStock',
    components: {
        'MutasiStock-grid': MutasiStockGrid,        
        'MutasiStock-form': MutasiStockForm,
        'item-form' : ItemDataForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Mutasi Stock');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.MutasiStockModal.addClick();
        },
        addItemClick(data){
            this.$refs.itemDataForm.addClick(data);
        },
        saveMutasiStockItemGrid(data){
            this.$refs.MutasiStockModal.MutasiStockDetailGridData(data);
        },
        editClick(stockData, view){
            this.$refs.MutasiStockModal.editClick(stockData, view);
        },
        onChange(){
            this.reload();
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReject").on('click', function() {
                    vue.$refs.grid.changeStatus('Reject');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
            })
        },
        
    }
}
</script>

<style scoped>
</style>